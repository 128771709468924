import React from "react"
import { Layout } from "../components/global"
import {
  Hero,
  PortfolioContainer,
  PortfolioControl,
  Services,
  Testimonial,
} from "../components/Home"
import CravingsPortfolioImage from "../../static/assets/portfolio/cravings.png"
import LoanPlacePortfolioImage from "../../static/assets/portfolio/loan-place.png"
import KvidPortfolioImage from "../../static/assets/portfolio/kvid.png"
import HomeAction from "../components/Home/HomeAction"
// import HomeInquiry from "../components/Home/HomeInquiry/HomeInquiry"
// import FreeWebsiteSection from "../components/Home/FreeWebsiteSection"

export default function Home() {
  return (
    <Layout>
      <Hero />
      {/* <FreeWebsiteSection /> */}
      {/* <HomeInquiry /> */}
      <Services />
      {/* <PortfolioContainer
        title=" -  designing food delivery app for 15.3 million users within Lagos."
        tags={["iOs App Dev", "Android App Dev", "UI/UX Design"]}
        description="Cravings food delivery app connects restaurants online and helps its customers place orders nearby to get it delivered at doorsteps. The app includes various features including live tracking."
        image={CravingsPortfolioImage}
        direction="left"
        portfolioName="Cravings"
        portfolioLink="https://www.cravingsng.com"
        portfolioNameColor="#F44A4A"
        showHeader={true}
      />
      <PortfolioContainer
        title=" -  A loan platform design to help users access loan from any loan company of their choice."
        tags={["iOs App Dev", "Android App Dev", "UI/UX Design"]}
        description="Loan Place is a platform built to help loan seakers, get access to different loan companies, and have a choice of selecting a loan company that best suit their interest."
        image={LoanPlacePortfolioImage}
        direction="right"
        portfolioName="Loan Place"
        portfolioNameColor="#1385C5"
      />
      <PortfolioContainer
        title=" -  A video creation web app built to upscale online businesses."
        tags={["Web App", "Website", "UI/UX Design"]}
        description="Kvid is a video creation and editing app that allows users to create, edit videos,for business advertisement purposes, social media, birthday etc in minutes. There are video templates available to make your creative process flow easy enabling you craft videos in seconds."
        image={KvidPortfolioImage}
        direction="left"
        portfolioName="K Vid"
        portfolioNameColor="#2F80ED"
      />
      <PortfolioControl /> */}
      <Testimonial />
      <HomeAction />
    </Layout>
  )
}
