import React from "react"
import LogoAlt from "../../../static/assets/brand/logo-alt.svg"

function Testimonial() {
  return (
    <div className="testimonial-container">
      <h3 className="testimonial-header">CLIENTS SAY</h3>
      <div className="testimonial-content-container">
        <div className="testimonial-content">
          <div className="testimonial-image-container">
            <img
              className="testimonial-image"
              // src="https://picsum.photos/150"
              src={LogoAlt}
              alt=""
            />
          </div>
          <div className="testimonial-quote">“</div>
          <div className="testimonial-testimony">
            After working with Keyla on a project, I then discovered that it was
            a start up. I was surprised because, I didn’t expect so much
            professionalism and commitment to a project. Their communication
            skills were excellent. In fact, I love the way they work together as
            a team. Thanks Keyla.
          </div>
          <div className="testimonial-author">Isoko Walter</div>
        </div>
        <div className="testimonial-content">
          <div className="testimonial-image-container">
            <img
              className="testimonial-image"
              // src="https://picsum.photos/150"
              src={LogoAlt}
              alt=""
            />
          </div>

          <div className="testimonial-quote">“</div>
          <div className="testimonial-testimony">
            Keyla is a company that is very interested in customer satisfaction.
            They pay attention to details and they communicate effectively. I
            now prefer working with a professional company than working with an
            individual developer. It provides structure, process and
            professionalism.
          </div>
          <div className="testimonial-author">Godwin Uzor</div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
