import React from "react"
import "./home.css"
import Carousel from "react-material-ui-carousel"
import Slide1 from "./Hero-Slides/Slide1"
import Slide2 from "./Hero-Slides/Slide2"
// import Slide3 from "./Hero-Slides/Slide3"

const Hero = () => {
  return (
    <Carousel
      sx={{
        width: "100%",
        margin: 0,
        height: "100%",
        backgroundColor: "#000",
      }}
      autoPlay={true}
      animation="fade"
      swipe={true}
      indicators={false}
      navButtonsAlwaysVisible={true}
      // fullHeightHover={true}
    >
      <Slide1 />
      <Slide2 />
      {/* <Slide3 /> */}
    </Carousel>
  )
}

export default Hero
