import React from "react"
import "./home.css"
// import ImageBG from "../../../static/assets/services/services-image.svg"
import WebDevelopmentIcon from "../../../static/assets/services/web-development.svg"
import MobileDevelopmentIcon from "../../../static/assets/services/mobile-development.svg"
import UserExperienceIcon from "../../../static/assets/services/user-experience.svg"
import ExpertIcon from "../../../static/assets/services/expert.svg"

const Services = () => {
  return (
    <div className="services-container">
      <div className="services-content-list-container">
        <div className="service-content">
          <div className="service-content-title-container">
            <img src={WebDevelopmentIcon} alt="" className="service-icon" />
            <div className="service-content-title">Web Development</div>
          </div>
          <div className="service-content-divider"></div>
          <div className="service-content-description">
            We create brands that drive profits, increase margins, and enrich
            interactions with product.
          </div>
        </div>
        <div className="service-content">
          <div className="service-content-title-container">
            <img src={MobileDevelopmentIcon} alt="" className="service-icon" />

            <div className="service-content-title">Mobile App Development</div>
          </div>
          <div className="service-content-divider"></div>
          <div className="service-content-description">
            Create an impactful mobile app that fits your brand and industry
            within a short time frame.
          </div>
        </div>
        <div className="service-content">
          <div className="service-content-title-container">
            <img src={UserExperienceIcon} alt="" className="service-icon" />

            <div className="service-content-title">UI/UX Design</div>
          </div>
          <div className="service-content-divider"></div>
          <div className="service-content-description">
            We descover problems and design solutions for your projects with our
            experience design team.
          </div>
        </div>
        <div className="service-content">
          <div className="service-content-title-container">
            <img src={ExpertIcon} alt="" className="service-icon" />

            <div className="service-content-title">Hire Expert(s)</div>
          </div>
          <div className="service-content-divider"></div>
          <div className="service-content-description">
            Our IT consulting will help you automate and digitalise operations,
            optimise the software, and implement the latest technologies.
          </div>
        </div>
      </div>
      <div className="services-description-container">
        <div className="services-description-title-container">
          <div className="services-description-title-bar"></div>
          <h3 className="services-description-title-text">Services</h3>
        </div>
        <div className="services-description-header">
          Delivering all round software solution that lasts
        </div>
        <div className="services-description-content">
          Keyla is a company focused on delivering quality technological
          services to customers at all time, in order to drive business
          efficiency and customer satisfaction. By harnessing our modern
          technological methods and teams, we are able to build products and
          provide services that makes life better for businesses and individuals
          alike.
        </div>
      </div>
    </div>
  )
}

export default Services
