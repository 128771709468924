import { Link } from "gatsby"
import React from "react"
import PartnersIcons from "../../../../static/assets/homepage/new_partners.png"
import "../home.css"

function Slide2() {
  return (
    <div className="hero-container2">
      <div>
        <h1 className="hero-title">Flexible payment options</h1>

        <h2 className="hero-subtitle">
          We offer flexible payment options for projects
        </h2>
      </div>

      <div
        style={{
          flexGrow: 1,
        }}
      >
        <Link
          to="/services#service-page-inquiry"
          style={{
            textDecoration: "none",
          }}
        >
          <button className="hero-button">GET STARTED</button>
        </Link>
      </div>
      <div className="hero-footer">
        <span>Our Clients / partners...</span>
        <img src={PartnersIcons} alt="Partners" className="partners-image" />
      </div>
    </div>
  )
}

export default Slide2
